body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
 }

@font-face {
  font-family: "Barlow";
  src: local("Barlow"),
   url("./fonts/Barlow/Barlow-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "nunito";
  src: local("nunito"),
   url("./fonts/nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "nunito-semibold";
  src: local("nunito-semibold"),
   url("./fonts/nunito/Nunito-SemiBold.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
 }