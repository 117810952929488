body {
    max-width: 1920px;
    align-items: center;
}

.navbar-items {

    color: #77838F;
    font-family: 'nunito';
    font-size: 20px;
    padding: 10px;
    margin-top: 10px;

    
}

.navbar-items :hover {

    color: #000000;
    font-family: 'nunito';
    text-decoration: none;

    
}


.navbar-item-last :hover {

    color: #000000;
    font-family: 'nunito';
    text-decoration: none;

    
}


.navbar-item-last {

    color: #77838F;
    font-family: 'nunito';
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 20px;
    
}

.bluebg {
    background-color: #21A2DB;
    padding-top: 70px;
}

.lightbg {
    background-color: #f5f5f5;
    min-height: 200%;
}

.body-text-top {

    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "nunito";
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #4B5054;

}

.body-text {

    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "nunito";
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;

}

.body-text-narrow {

    padding-top: 10px;
    padding-bottom: 20px;
    font-family: "nunito";
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;

}

.head-text-top {


font-family: "Montserrat";
font-size: 60px;
font-weight: bold;
line-height: 71px;
font-style: normal;
text-align: left;
color: #000000;

}

.whitetext {
    color: #ffffff;
}

.head-text {
    font-family: "Montserrat";
    font-size: 54px;
    font-weight: bold;
    line-height: 66px;
    font-style: normal;
}

.head-text-m {
font-family: "Montserrat";
font-size: 54px;
font-weight: bold;
line-height: 54px;
font-style: normal;
text-align: center;
padding: 40px;
}

.hamburger {
    width: 2rem;
    height: 2rem;
    display: none;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    cursor: pointer;
    margin-right: 20px;
}



.navbar {
    position: sticky;
}



.burger {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: #21A2DB;
    transform-origin: 1px;
    transition: all 0.3s linear;
    margin: 5px 0px;
}

.head-text-s {
font-family: "Montserrat";
font-size: 43px;
font-weight: bold;
line-height: 57px;
font-style: normal;
text-align: center;
color: #ffffff;
margin-top: 150px;
margin-bottom: 100px;
}

.articleLink {
    font-family: 'nunito';
    padding: 20px 120px;
    text-decoration: none;
    color:#000000;
    font-size: 30px;
}




.darkbluebg {
    background-color: #344A9E;
    min-height: 200%;
}

.lightbluebg{
    background-color: #F3FAFD;
    min-height: 200%;
}

.bluetext {
    color: #21A2DB;
    font-weight: bold;
}

.darkbluetext {
    color: #344a9e;
    font-weight: bold;
}

.tabular{
    padding-left: 200px;
    padding-right: 200px;
}

.tabular-header{
    display: flex;
    background-color: #E5F0FF;
    text-align: center;
    font-size: 24px;
    font-family: 'nunito';
    line-height: 30px;
    font-weight: bold;
    color: #344A9E;
    min-height: 120px;
    vertical-align: middle;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.tabular-body{

opacity: 0.75;
font-family: "nunito";
font-size: 18px;
font-weight: normal;
line-height: 28px;
font-style: normal;
text-align: center;
color: #4b5054;
padding-top: 25px;
padding-left: 5px;
padding-right: 5px;

}

span .tabular-header{
    vertical-align: middle;
}

.modalButton,
.modalButton:active,
.modalButton:focus {
  outline: none;
  border: none;
}

.callButton {

    background-color: #344a9e; 
    border: none;

    min-height: 63px;
    min-width: 166px;

    padding: 20px;

    font-family: "nunito-semibold";
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border-radius: 2px;

}

.callButtonNav {

    background-color: #344a9e; 
    border: none;

    min-height: 50px;
    min-width: 166px;

    padding: 15px;

    font-family: "nunito-semibold";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border-radius: 2px;

}

.modalButton {
    border: 0px;
    background-color: inherit;
    cursor: pointer;
}


.submitButton {
    background-color: #344a9e; 

    min-height: 50px;
    min-width: 166px;

    padding: 20px;
    margin-top: 40px;

    font-family: "nunito-semibold";
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ffffff;
    transition-duration: 0.4s;
    border-radius: 4px;
    cursor: pointer;

}

.submitButton:hover {
    background-color: #ffffff; 

    min-height: 50px;
    min-width: 166px;

    padding: 20px;
    margin-top: 30px;

    font-family: "nunito-semibold";
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: #344a9e;
    border: 1px solid #344a9e;
    border-radius: 8px;
}

.InterestText{
    border-style: solid;
    border-color: #21A2DB;
    border-width: 1px;
    margin-bottom: 23px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
}

.InterestTextBody{

opacity: 0.75;
font-family: "nunito";
font-size: 18px;
font-weight: normal;
line-height: 28px;
font-style: normal;
text-align: left;
color: #4b5054;
padding-top: 20px;

}

.InterestTextHead{

font-family: "nunito";
font-size: 30px;
font-weight: bold;
line-height: 30px;
font-style: normal;
text-align: left;
color: #344a9e;

}

form {
    padding: 20px;
    width: 400px;
    align-items: center;
}

.form-align {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
}
   
.portfolioLogoImage {
    display: block;
    max-width: 150px;
    max-height: 100px;
    mix-blend-mode: multiply;
    filter: contrast(1);
    /* padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 50px; */
}
.portfolioLogoImageLg {
    display: block;
    max-width: auto;
    height: 150px;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 50px;
    mix-blend-mode: multiply;
    filter: contrast(1);
}

.logo{
    max-width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.areasImg{
    max-width: 75px;
    height: auto;
    margin: 5px;
    margin-right: 20px;
}

.Image1{
    max-width: 100%;
    height: auto;
}

.Image2{
    max-width: 75%;
    height: auto;
}

.Image3{
    max-width: 75%;
    height: auto;
    margin-top: 200px;
    margin-right: 100px;
}

.profileImage {
    width: 350px;
    max-height: 350px;
    border-radius: 50%;
    border-width: 7px;
    border-style: solid;
    border-color: #21A2DB;
    transition-duration: 0.4s;
}

.profileImage:hover {
    width: 375px;
    max-height: 375px;
    border-radius: 50%;
    border-width: 7px;
    border-style: solid;
    border-color: #21A2DB;
    
}

.profileName {

    font-family: "Montserrat";
    font-size: 34px;
    font-weight: normal;
    line-height: 55px;
    font-style: normal;
    text-align: center;
    color: #344a9e;
    margin-top: 30px;
}

.tabPadding {
    padding-top: 90px;
    padding-left: 70px;
    padding-bottom: 70px;
}

.tabPaddingTop {
    padding-top: 90px;
}

.newsLogo{
    max-width: 150px;
    height: auto;
    mix-blend-mode: multiply;
    filter: contrast(1);
}


@media (max-width: 767px) {

    .hamburger {
        display: block;
        padding-top: 10px;

    }

    .navbar , .navigation {
        display: none;
    }

    .head-text, .head-text-top, .head-text-m {
        font-size: 40px;
    }

    .body-text , .body-text-top , .body-text-narrow {
        font-size: 16px;
    }
    
    .profileImage {
        max-width: 200px;
        height: auto;
    }

    .callButton, .callButtonNav {
        min-height: 50px;
        min-width: 120px;
    
        padding: 10px;
    
        font-family: "nunito-semibold";
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border-radius: 2px;
    
    }

    .tabPadding {
        padding-left: 30px;
        padding-right: 30px;
    }

    
}

@media (max-width: 1050px) {
    .navbar-items {
        font-size: 15px;
        padding: 10px 5px;
        margin: 10px 5px;
    }

    .navbar-item-last {
        font-size: 15px;
    }

    .articleLink {
        padding: 20px;
        font-size: 24px;
    }

    .newsLogo {
        max-width: 90px;
    }

    .areasImg {
        max-width: 50px;
    }

    .InterestTextHead{
        font-size: 25px;
    }

    .InterestTextBody{
        font-size: 15px;
    }

    .tabular-header{
        font-size: 20px;
    }

    .tabular-body{
        font-size: 16px;
    }

    .portfolioLogoImage {
        max-width: 120px;
        max-height: 80px;
        padding: 10px;
        mix-blend-mode: multiply;
        filter: contrast(1);
    }

    .logo{
        max-width: 80px;
    }

    .Image1, .Image2, .Image3 {
        margin: 0px;
    }
}